import React from 'react'

import './App.css'
import Themes from './theme'
import 'materialize-css/dist/css/materialize.min.css'
import M from 'materialize-css'
import {basePath,cacheHeaders} from './config'
import logo from './assets/Breezebook.png'

import Service from './components/service/service'
import Staff from './components/staff/staff'
import Schedule from './components/schedule/schedule'
import Spinner from './components/spinner/spinner'
import Summary from './components/summary/summary'
import PayPal from './components/paypal/paypal'

const totalSteps = 5;

const dateOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
}

const initialClient = {
  name: '',
  number: '',
  email: ''
}

class App extends React.Component{
  state={
    isValid: true,
    apptConfirmed: false,
    isLoading: true,
    formOpen: false,
    currentState: 0,
    compName: '',
    locations: [],
    selectedLocation: 0,
    locationName: '',
    services: [],
    selectedService: 0,
    serviceName: '',
    staff:[],
    selectedStaff: 0,
    staffName: '',
    start_date: new Date().toLocaleDateString('en-US',dateOptions),
    schedule:[],
    orderedSchedule: {},
    activeSchedule: 0,
    selectedSchedule: 0,
    selectedDate: '',
    selectedSlot: '',
    clientDetails: {...initialClient},
    paymentEnabled: false, // For whole application
    paymentRequired: false, // For selected Service
    taxPercent: 0,
    appointmentId: 0
  }

  progress = React.createRef()

  toaster = (success,message) => {
    let classList = ['rounded'];
    success ? classList.push('ToastSuccess') : classList.push('ToastFailure');
    M.toast({html: message, classes: classList.join(' ')});
  }

  applyTheme = (mode) => {
    const theme = Themes[mode];
    Object.keys(theme).forEach(key => {
        document.documentElement.style.setProperty(`--${key}`,theme[key]);
    })
  }

  formOpenHandler = () => {
    this.setState((prevState) => {
      return {formOpen : !prevState.formOpen,clientDetails: {...initialClient}}
    });
    document.getElementById('ClientForm').reset();
  }

  matInitializer = () => {
    let collElems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(collElems);
    let dateElem = document.querySelectorAll('.datepicker');
    M.Datepicker.init(dateElem, {onSelect: this.dateSelectHandler,defaultDate: new Date(this.state.start_date),setDefaultDate:true,minDate: new Date(),autoClose: true});
  }

  currentStateModifier = (dir) => {
    let curr = this.state.currentState;
    if(dir === 'back' && curr > 0){
      this.setState({currentState: curr-1});
    }
    if(dir === 'next' && curr < totalSteps - 1){
      this.setState({currentState: curr+1});
    }
  }

  getEndDate = (date) => {
    let startDate = new Date(date);
    let daysOffset = 14;
    //  Customization for MBS Fitness
    if(this.state.compName === 'MBS Fitness'){
      daysOffset = 2;  
    }
    return new Date(startDate.setDate((startDate.getDate()) + daysOffset)).toLocaleDateString('en-US',dateOptions);
  }

  locationSelectHandler = (id,name) => {
    this.setState({isLoading:true,selectedLocation: id,locationName:name,currentState:1,selectedService:0,services:[],selectedStaff:0,staff:[],schedule:[],orderedSchedule:{},selectedSchedule:0,start_date: new Date().toLocaleDateString('en-US',dateOptions)});
    this.progress.current.style.width = 1*(100/totalSteps) + '%';
    fetch(basePath+'locationService?location_id='+id,{
      headers: { 
          ...cacheHeaders,
       }
    })
    .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
    .then(result => {
      if(result.success){
        this.setState({services: result.data,isLoading:false});
        if(result.data.length === 1)
         this.serviceSelectHandler(result.data[0].service_id,result.data[0].name);
      }else{
        this.toaster(false, result.message);
        this.setState({isLoading:false});
    }
    })
    .catch(err => {
      this.toaster(false, 'An error occurred and the action could not be completed!');
      this.setState({isLoading:false});
    })
    this.getPaypalInfo(id);
  }

  getPaypalScript = (clientId, currency) => {
    let URL = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}`;
    const script = document.createElement("script");
    script.src = URL;
    document.body.appendChild(script);
  }

  getPaypalInfo = (id) => {
    this.setState({isLoading: true});
    fetch(basePath+'paypalAccountInfo?location_id='+id,{
      headers: { 
          ...cacheHeaders,
       }
    })
    .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
    .then(result => {
      if(result.success){
        if(result.data){
          this.getPaypalScript(result.data.clientId,result.data.currency);
          this.setState({paymentEnabled: !!result.data.payment_enable, taxPercent:result.data.taxPercent, isLoading:false});
        }
      }else{
        this.toaster(false, result.message);
        this.setState({isLoading:false});
    }
    })
    .catch(err => {
      this.toaster(false, 'An error occurred in fetching paypal script!');
      this.setState({isLoading:false});
    })
  }

  isPaymentRequired = (id) => {
    const service = this.state.services.find(service => service.service_id === id);
    if(service.paymentRequired && +service.bookingAmount !== 0 && +service.bookingAmount <= +service.cost)
      this.setState({paymentRequired: true});
  }

  serviceSelectHandler = (id,name) => {
    this.setState({isLoading:true,selectedService: id, serviceName:name,currentState:2,selectedStaff:0,staff:[],schedule:[],orderedSchedule:{},selectedSchedule:0,start_date: new Date().toLocaleDateString('en-US',dateOptions)});
    this.progress.current.style.width = 2*(100/totalSteps) + '%';
    fetch(basePath+'serviceStaff?service_id='+id,{
      headers: { 
          ...cacheHeaders,
       }
    })
    .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
    .then(result => {
      if(result.success){
        this.setState({staff: result.data,isLoading:false});
        if(result.data.length === 1)
          this.staffSelectHandler(result.data[0].staff_id,result.data[0].first_name+' '+result.data[0].last_name);
      }else{
        this.toaster(false, result.message);
        this.setState({isLoading:false});
    }
    })
    .catch(err => {
      this.toaster(false, 'An error occurred and the action could not be completed!');
      this.setState({isLoading:false});
    })
    this.isPaymentRequired(id);
  }

  getAvailability = (id, startDate) => {
    let url = basePath+'staffAvailability?staff_id='+id+'&location_id='+this.state.selectedLocation+'&service_id='+
    this.state.selectedService+'&start_date='+startDate+'&end_date='+this.getEndDate(startDate);
    fetch(url,{
      headers: { 
        ...cacheHeaders,
      }
    })
    .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
    .then(result => {
      if(result.success){
        if(result.data.length !== 0){
        let orderedSchedule = {};
        result.data.forEach(element => {
          if(! orderedSchedule[element.day+' - '+element.date])
          orderedSchedule[element.day+' - '+element.date] = {};
          orderedSchedule[element.day+' - '+element.date][element.schedule_id] = element.slot;
        });
        this.setState({schedule: result.data, orderedSchedule: orderedSchedule,isLoading:false});
        }else{
          this.setState({isLoading:false});
        }
      }else{
        this.toaster(false, result.message);
        this.setState({isLoading:false});
      }
    })
    .catch(err => {
      this.toaster(false, 'An error occurred and the action could not be completed!');
      this.setState({isLoading:false});
    })
  }

  staffSelectHandler = (id,name) => {
    this.setState({isLoading:true,selectedStaff: id, staffName:name,currentState:3,schedule:[],orderedSchedule:{},selectedSchedule:0,start_date: new Date().toLocaleDateString('en-US',dateOptions)});
    this.progress.current.style.width = 3*(100/totalSteps) + '%';
    this.getAvailability(id, new Date().toLocaleDateString('en-US',dateOptions));
  }

  dateSelectHandler = (val) => {
    this.setState({start_date: new Date(val).toLocaleDateString('en-US',dateOptions),isLoading:true,schedule:[],orderedSchedule:{},selectedSchedule:0});
    this.getAvailability(this.state.selectedStaff, new Date(val).toLocaleDateString('en-US',dateOptions));
  }

  timeSelectHandler = (schedule,slot,presentDate) => {
    this.setState({currentState:4,selectedSchedule:schedule,selectedSlot:slot,selectedDate:presentDate});
    this.progress.current.style.width = 4*(100/totalSteps) + '%';
  }

  componentDidMount(){
    M.AutoInit();
    let compIdentifier = decodeURI(window.location.pathname.split('/')[1]);
    if(compIdentifier !== ""){
      this.setState({compName: compIdentifier});
      fetch(basePath+'companyLocation?name='+compIdentifier,{
        headers: { 
            ...cacheHeaders,
         }
      })
      .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
      .then(result => {
        if(result.success){
          if(result.data.length !== 0){
            this.setState({locations: result.data,isLoading:false});
            // Setting initial theme.
            this.applyTheme(result.data[0].theme);
            if(result.data.length === 1)
              this.locationSelectHandler(result.data[0].location_id,result.data[0].title);
          }else{
            this.setState({isLoading: false, isValid: false});
          }
        }else{
          this.toaster(false, result.message);
          this.setState({isLoading:false});
      }
      })
      .catch(err => {
        this.toaster(false, 'An error occurred and the action could not be completed!');
        this.setState({isLoading:false});
      })
    }else{
      this.setState({isLoading: false, isValid: false});
    }
  }

  componentDidUpdate(prevProps,prevState){
    if(this.state.currentState === 3){
      this.matInitializer();
    }
  }

  sortSchedules = (key1,key2) => {
    let date1 = new Date(key1.split(' ')[2]), date2 = new Date(key2.split(' ')[2])
    return date1 > date2 ? 1 : -1
  }

  inputChangeHandler = (ev) => {
    let client = {...this.state.clientDetails};
    client[ev.target.name] = ev.target.value;
    this.setState({clientDetails: client});
  }

  submitDetails = (payment) => {
    this.setState({isLoading: true});
    let postData = {
      location_id: this.state.selectedLocation,
      staff_id: this.state.selectedStaff,
      service_id: this.state.selectedService,
      schedule_id: this.state.selectedSchedule,
      date: this.state.selectedDate,
      time: this.state.selectedSlot,
      first_name: this.state.clientDetails.name,
      number: this.state.clientDetails.number,
    }
    if(this.state.clientDetails.email !== ''){
      postData['email'] = this.state.clientDetails.email;
    }

    let apiKey = payment ? 'appointmentWithPay' : 'appointment';

    fetch(basePath + apiKey,{
      headers:{
          'Content-Type': 'application/json',
          ...cacheHeaders,
      },
      method: 'post',
      body: JSON.stringify(postData)
    })
    .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
    .then(result => {      
        if(result.success){
          // this.formOpenHandler();
          this.setState({isLoading:false,apptConfirmed: !payment,appointmentId: result.appointment_id || 0,currentState: 5, formOpen: false});
        }else{
          this.setState({isLoading: false});
          this.toaster(result.success, result.message);
        }
    })
    .catch(err => {
        this.toaster(false, 'An error occurred and the action could not be completed!');
        this.setState({isLoading: false});
    })
  }

  formSubmitHandler = (ev) => {
    ev.preventDefault();
    this.progress.current.style.width = 5*(100/totalSteps) + '%';
    this.submitDetails(this.state.paymentEnabled && this.state.paymentRequired);
  }

  render(){
    return(
      <div className="App">
        <header className="AppHeader">
          <h4>Book an appointment with us!</h4>
        </header>
        {
          this.state.isValid ? 
          <main className="MainContent">
          <div className="ProgressBar Animated">
            <span ref={this.progress} style={{width:'0%'}}><span></span></span>
          </div>
          {
            this.state.apptConfirmed ? 
            <div className='ConfirmationContainer'>
              <i style={{color:'#4CAF50'}} className="large material-icons">check_circle</i>
              <p style={{margin:'10px'}}>Your appointment is confirmed.</p>
              <p style={{margin:'10px'}}>You will receive a notification shortly!</p>
            </div> : 
            <div className="MainContainer card">
            { this.state.isLoading ? <Spinner /> :
              <>
              <div className='ActionDiv'>
                <button style={{padding:'0px'}} className='btn btn-flat ActionBtn' disabled={this.state.currentState <= 0 || this.state.currentState >= totalSteps} onClick={()=>this.currentStateModifier('back')}><i style={{marginRight:'2px'}} className="material-icons left">arrow_back</i>Back</button>
                <h5 style={{fontWeight:'bold',color:'var(--secondary-font-color)'}}>{this.state.compName}</h5>
                <button style={{padding:'0px'}} className='btn btn-flat ActionBtn' disabled={this.state.currentState >= totalSteps-1} onClick={()=>this.currentStateModifier('next')}><i style={{marginLeft:'2px'}} className="material-icons right">arrow_forward</i>Next</button>
              </div>
              { 
              this.state.currentState === 0 ?       
              <div className="SectionContainer">
                <h5>Select Location</h5>
                <div className='ChipContainer'>
                  {
                    this.state.locations.length !== 0 ?
                    this.state.locations.map(location => {
                      let locationClasses = ['chip','card'];
                      location.location_id === this.state.selectedLocation && locationClasses.push('Checked')
                      return (
                      <div key={location.location_id} 
                        onClick={()=> this.locationSelectHandler(location.location_id,location.title)} 
                        className={locationClasses.join(' ')}>
                          {location.title}
                      </div>
                    )}) : <h6>Service Not available at the moment!</h6>
                  }
                </div>
              </div> : null}
              { this.state.currentState === 1 && 
              <div className="SectionContainer">
                <h5>Select Service</h5>
                {
                  this.state.selectedLocation !== 0 ? 
                  <div className='ServiceContainer'>
                  {
                    this.state.services.length !== 0 ?
                    this.state.services.map(service => (
                      <Service key={service.service_id} 
                        clicked={()=> this.serviceSelectHandler(service.service_id,service.name)}
                        name={service.name}
                        description={service.description}
                        duration={service.duration_min}
                        cost={service.cost}
                        checked={service.service_id === this.state.selectedService} />
                    )) : <h6>Services Not available at the moment!</h6>
                  }
                </div> : <h6>Please Select a Location</h6>
                }
              </div>
              }
              { this.state.currentState === 2 &&
              <div className="SectionContainer">
                <h5>Click any one to check availability</h5>
                {
                  this.state.selectedService !== 0 ? 
                  <div style={{marginTop: '50px'}}>
                  {
                    this.state.staff.length !== 0 ?
                    this.state.staff.map(staff => (
                      <Staff key={staff.staff_id} 
                      name={staff.first_name+' '+staff.last_name} 
                      email={staff.email} 
                      clicked={()=>this.staffSelectHandler(staff.staff_id,staff.first_name+' '+staff.last_name)}
                      checked={staff.staff_id === this.state.selectedStaff} />
                    )) : <h6>Staff Not available at the moment!</h6>
                  }
                </div> : <h6>Please Select a Service</h6>
                }
              </div>
              }
              {
                this.state.currentState === 3 &&
                <div className='SectionContainer'>
                  <h5>Choose a time slot of your convenience</h5>
                  {/* Customization for MBS Fitness */}
                  {this.state.compName !== "MBS Fitness" && <div className="input-field col s12 DateContainer">
                  <i className="material-icons">today</i>
                    <input id='startDate' type="text" className="datepicker"></input>
                  </div>}
                  {
                    this.state.selectedStaff !== 0 ? 
                    <div className='ScheduleContainer'>
                      {
                        this.state.schedule.length !== 0 ?
                        <>
                        <p style={{color:'var(--secondary-font-color)'}}>Showing availability for 2 weeks from the selected date</p>
                        <ul className="collapsible popout">
                          {
                            Object.keys(this.state.orderedSchedule).sort(this.sortSchedules).map( (key,index) => (
                              <Schedule 
                              clicked={this.timeSelectHandler} 
                              key={key} 
                              date={key}
                              active = {this.state.activeSchedule === index}
                              setActive = {() => this.setState({activeSchedule: index})}
                              selectedSchedule={this.state.selectedSchedule}
                              selectedSlot={this.state.selectedSlot} 
                              slots={this.state.orderedSchedule[key]} />
                            )) 
                          }
                        </ul>
                        </> : <h6>Schedule not available for the selected date. Please choose another date!</h6>
  
                      }
                    </div> : <h6>Please Select a Staff</h6>
                  }
                </div>
              }
              {
                this.state.currentState === 4 && 
                <div className='SectionContainer'>
                  <h5>Appointment Summary</h5>
                  {
                    this.state.selectedSchedule !== 0 ?
                    <>
                    <Summary 
                      location={this.state.locationName}
                      service={this.state.serviceName}
                      staff={this.state.staffName}
                      appointment={this.state.selectedDate+', ' +this.state.selectedSlot} /> 
                    <button onClick={this.formOpenHandler} disabled={this.state.appointmentId !== 0} className='btn SubmitBtn'><i className="material-icons right">chevron_right</i>Confirm</button>
                    </> : <h6>Please Select a date and time</h6>
                  }                
                </div>
              }
              {
                this.state.currentState === 5 && 
                <div className='SectionContainer'>
                  <h5>Checkout</h5>
                  {
                    this.state.paymentEnabled && this.state.paymentRequired && this.state.clientDetails.name !== '' ?
                    <PayPal 
                      confirm={()=>this.setState({apptConfirmed: true})}
                      appointmentId = {this.state.appointmentId}
                      client={this.state.clientDetails.name}
                      taxPercent = {+this.state.taxPercent}
                      service = {this.state.services.find(service => service.service_id === this.state.selectedService)}
                    /> : <h6>Payment Options not available yet.</h6>
                  }                 
                </div>
              }
              </>
              }
              <div className={'FormContainer card-panel '+ (this.state.formOpen ?'FormOpen' : '')}>
                <div className='FormHeader'>
                    <h5 style={{color:'#0486a9'}}>{'Your Details please!'}</h5>
                    <i style={{cursor:'pointer'}} onClick={this.formOpenHandler} className="material-icons">close</i>
                </div>
                <form onSubmit={this.formSubmitHandler} id='ClientForm' style={{width:'80%',height:'80%',overflowY:'auto',overflowX:'hidden'}}>
                  <div className="input-field col s12">
                    <input id="name" name="name" type="text" value={this.state.clientDetails.name} onChange={this.inputChangeHandler} required className="validate"></input>
                    <label htmlFor="name">Your Name</label>
                  </div>
                  <div className="input-field col s12">
                    <input id="number" name="number" type="tel" pattern="^([+]?[1]{1})?[ .-]?[(]?[0-9]{3}[)]?[ .-]?[0-9]{3}[ .-]?[0-9]{4}$" value={this.state.clientDetails.number} onChange={this.inputChangeHandler} required className="validate"></input>
                    <label htmlFor="number">Mobile</label>
                    <span className="helper-text" data-error="Enter a valid Mobile Number."></span>
                  </div>
                  <div className="input-field col s12">
                    <input id="email" name="email" type="email" pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:[.][a-zA-Z0-9-]+)+$" value={this.state.clientDetails.email} onChange={this.inputChangeHandler} className="validate"></input>
                    <label htmlFor="email">E-mail</label>
                    <span className="helper-text" data-error="Enter a valid email address."></span>
                  </div>
                  <p>** please fill the form to receive reminders of your appointment.</p>
                  <div className='FormAction'>
                    <button style={{color:'#4CAF50'}} className='btn-flat ActionBtn' type='submit' >Submit</button>
                    <button style={{color:'#F44336'}} onClick={this.formOpenHandler} className='btn-flat ActionBtn' type='button' >Cancel</button>
                  </div>
                </form>
              </div>
              {this.state.formOpen && <div onClick={this.formOpenHandler} className='BackDrop'></div>}
            </div>
          }


        </main> 
        : <h5 style={{marginTop:'250px', height:'60vh'}}>Not Registered for BreezeBook service! Contact <a href='https://breezemaxweb.com/'>BreezeMaxWeb</a> for more information.</h5>
        }
        <footer className='AppFooter'>
          <a href="https://breezebookapp.ca/" title="BreezeBook Info" target='_blank' rel="noopener noreferrer">
            <img src={logo} alt='BreezeBook' width='210' height='120' />
          </a>
          <p>Powered by <a href='https://breezemaxweb.com/' title="BreezeMaxweb" target='_blank' rel="noopener noreferrer">BreezeMaxWeb<span style={{fontSize:'25px'}}>&reg;</span> Online Media Solutions</a> | <a href='https://breezebook.app/privacy_policy.html' title="BreezeMaxweb Privacy Policy" target='_blank' rel="noopener noreferrer">Privacy Policy</a></p>
          {/* Paypal logo */}
          <a href="https://www.paypal.com/webapps/mpp/paypal-popup" title="How PayPal Works" target='_blank' rel="noopener noreferrer">
            <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/cc-badges-ppmcvdam.png" alt="Buy now with PayPal" />
          </a>
          {/* Paypal logo */}
        </footer>
      </div>
    )
  }
}

export default App;
