import React from "react";
import ReactDOM from "react-dom";
import M from 'materialize-css'
import {basePath,cacheHeaders} from '../../config'

import Spinner from "../spinner/spinner";
import './paypal.css'

window.React = React;
window.ReactDOM = ReactDOM;

let PayPalButton = null;

class Paypal extends React.Component {

  state = {
    showButtons: false,
    loading: true,
    error: false
  }

  timer = null;

  toaster = (success,message) => {
    let classList = ['rounded'];
    success ? classList.push('ToastSuccess') : classList.push('ToastFailure');
    M.toast({html: message, classes: classList.join(' ')});
  }

  submitPurchaseHistory = (status,purchaseUnits) => {
    this.setState({loading: true});
    let postData = {
      appointment_id: this.props.appointmentId,
      status: status,
      purchase_units: purchaseUnits
    }
    fetch(basePath + 'appointmentPayment',{
      headers:{
          'Content-Type': 'application/json',
          ...cacheHeaders,
      },
      method: 'post',
      body: JSON.stringify(postData)
    })
    .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
    .then(result => {      
        if(result.success && status === 'payment_success'){
          this.setState({loading:false, showButtons: false});
          this.toaster(true,"Appointment confirmed");
          this.props.confirm();
        }else{
          this.setState({loading: false,showButtons: false, error: true});
        }
    })
    .catch(err => {
        this.toaster(false, 'An error occurred and the payment history could not be captured!');
        this.setState({loading: false,showButtons: false, error: true});
    })
    clearTimeout(this.timer);
  }

  clearTransaction = () => {
    this.submitPurchaseHistory('payment_failure ',[]);
    this.toaster(false, "Transaction time expired");
  }

  componentDidMount() {
    if (window.paypal) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
    this.timer = setTimeout(this.clearTransaction, 25*60*1000);
  }

  componentDidUpdate(prevProps,prevState){
    const scriptJustLoaded = !prevState.showButtons && window.paypal;
    if (scriptJustLoaded) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM
        });
        this.setState({ loading: false, showButtons: true });
      }
  }

  componentWillUnmount(){
    clearTimeout(this.timer);
  }

  createOrder = (data, actions) => {
    const {service} = this.props;
    let cost = Number(service.bookingAmount).toFixed(2);
    // let tax = (service.bookingAmount * 13 / 100).toFixed(2);
    // let total = (Number(cost) + Number(tax)).toFixed(2);
    return actions.order.create({
      application_context: {
        brand_name: "MyTenix",
      },
      purchase_units: [
        {
          description: "BreezeBook Appointment",
          amount: {
            currency_code: sessionStorage.CURRENCY,
            value: cost
          }
        }
      ]
    });
  };

  onApprove = (data, actions) => {
    this.setState({loading: true});
    actions.order.capture().then(details => {
      if(details.status === 'COMPLETED'){
        this.toaster(true, "Payment Successful");
        console.log("Details: ", details);
        let payerEmail = details.payer.email_address;
        let orderId = details.id;
        let captures = details.purchase_units[0].payments.captures;
        const purchase_units = captures.map(capture => ({
          orderId: orderId,
          captureId: capture.id,
          captureAmount: capture.amount.value,
          payerEmail: payerEmail,
          currency: capture.amount.currency_code
        }));
        console.log(purchase_units);
        this.submitPurchaseHistory('payment_success',purchase_units);
      }else{
        this.toaster(false, details.status);
        this.setState({ loading:false, showButtons: true });
      }
    })
    .catch(err => {
      this.toaster(false, "An error occurred and the payment history could not be captured!");
      this.submitPurchaseHistory('payment_failure ',[]);
    });
  };

  render() {
    const { showButtons, loading, error } = this.state;
    const {service} = this.props;
    let cost = Number(service.bookingAmount).toFixed(2);
    // let tax = (service.bookingAmount * this.props.taxPercent / 100).toFixed(2);
    // let total = (Number(cost) + Number(tax)).toFixed(2);
    return (
      <>
        {loading && <Spinner />}

        {showButtons && (
          <div className='PaypalContainer card'>
            <p className='Greeting'>Hi <span>{this.props.client}</span>, We are excited to meet you. Please complete the payment to confirm your appointment.</p>
            <div className='CheckoutSummary'>
              <div className='CheckoutHeading'>
                <p>{(+service.bookingAmount === +service.cost ? service.name : 'Booking Charge')}</p>
                {/* <p>Tax</p>
                <p style={{fontWeight:'bold'}}>Total</p> */}
              </div>
              <div className='CheckoutHeading'>
                <p>:</p>
                {/* <p>:</p>
                <p style={{fontWeight:'bold'}}>:</p> */}
              </div>
              <div className='CheckoutValue'>
                <p>{'$ '+ cost}</p>
                {/* <p>{'$ '+ tax}</p>
                <p style={{fontWeight:'bold'}}>{'$ '+ total}</p> */}
              </div>
            </div>
            <PayPalButton
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
            />
          </div>
        )}

        {error && (
          <h5 style={{color:'#F44336'}}>Sorry! Your Appointment is not confirmed. Please contact the business if Payment has been charged.</h5>
        )}
      </>
    );
  }
}


 export default Paypal;
