const themes = {
    'Day' : {
        'primary-bg-color': '#222',
        'secondary-bg-color': '#6CD3F0',
        'secondary-font-color': '#0486a9'
    },
    'Night' : {
        'primary-bg-color': '#000',
        'secondary-bg-color': '#4dc',
        'secondary-font-color': '#008080'
    },
    'Vibrant' : {
        'primary-bg-color': '#00203F',
        'secondary-bg-color': '#FEE715',
        'secondary-font-color': '#00203F'
    }
}

export default themes;