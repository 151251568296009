import React from 'react'

import './schedule.css'

const Schedule = (props) => {

    const sortTimeSlots = (key1,key2) => {
        let time1 = props.slots[key1][0], time2 = props.slots[key2][0];
        let startM = time1.split(' ')[1];
        let startTime = Number(time1.split(' ')[0].split(':').join(''));
        let endM = time2.split(' ')[1];
        let endTime = Number(time2.split(' ')[0].split(':').join(''));
        if(startM === endM){
            startTime = startTime < 1200 ? startTime+1200 : startTime;
            endTime = endTime < 1200 ? endTime+1200 : endTime;
            return startTime > endTime ? 1 : -1
        }else{
            return startM > endM ? 1 : -1
        }
    }

    const presentDate = props.date.split(' ')[2];

    return (
        <li onClick={props.setActive} className={'Schedule '+(props.active?'active':'')}>
            <div className='collapsible-header'>
                <h6>{props.date}</h6>
                <i className="material-icons">unfold_more</i>
            </div>
            <div className='collapsible-body'>
            {
                Object.keys(props.slots).sort(sortTimeSlots).map(key => (
                    <div className='Slots' key={key}>
                    {
                        props.slots[key].map(slot => {
                            let classes = ['chip','TimeSlot'];
                            props.selectedSchedule === key && props.selectedSlot === slot && classes.push('Checked')
                            return(
                            <div key={slot} onClick={()=>props.clicked(key,slot,presentDate)} className={classes.join(' ')}>{slot}</div>
                        )})
                    }
                    </div>
                ))
            }
            </div>
        </li>
    )
}

export default Schedule;