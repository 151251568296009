import React from 'react'

import './staff.css'

const Staff = (props) => {
    let classList = ['Staff','chip'];
    props.checked && classList.push('Checked')
    return(
        <div className={classList.join(' ')} onClick={props.clicked}>
            <span>{props.name}</span>
            {/* <div className={classList.join(' ')}>
                <h6 style={{fontWeight:'bold'}}>{props.name}</h6>
                <span>{props.email}</span>
            </div> */}
        </div>
    )
}

export default Staff;