import React from 'react'

import './service.css'

const Service = (props) => {
    let classList = ['ServiceContent','card'];
    props.checked && classList.push('Checked');
    return(
        <div className='Service' onClick={props.clicked}>
            <div className={classList.join(' ')}>
                <div className='ServiceDetail'>
                    <h6 style={{fontWeight:'bold'}}>{props.name}</h6>
                    <p>{props.description}</p>
                </div>
                <div className='ServiceDetail'>
                    <p>{'Duration(in mins): '}<b>{props.duration}</b></p>
                    <p>{'Approx. Cost: '}<b>{'$ '+(+props.cost ? props.cost : '')}</b></p>
                </div>
            </div>
        </div>
    )
}

export default Service;