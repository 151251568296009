import React from 'react'
import './summary.css'

const Summary = (props) => {
    return (
        <div className='SummaryContainer card'>
            <div className='SummaryHeadings'>
                <p>Location</p>
                <p>Service</p>
                <p>Staff</p>
                <p>Appointment</p>
            </div>
            <div className='SummaryHeadings'>
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p>:</p>
            </div>
            <div className='SummaryContent'>
                <p>{props.location}</p>
                <p>{props.service}</p>
                <p>{props.staff}</p>
                <p>{props.appointment}</p>
            </div>
        </div>
    )
}

export default Summary;
